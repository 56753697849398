/*
NOTES:

side-padding: 0.25rem;
max-section-width: 1024px;

dark-grey: #353535;
middle-grey: #494949;

brown: #d0a888;
blue: #AFEEEE;
pink: #ffb6c1;

grid: 16 rows at 64px;
 */

body {
	font-family: 'Quicksand', sans-serif;
}

body * {
	box-sizing: border-box;
}

.marker {
	font-family: 'Permanent Marker', cursive;
}

section.page-section {
	height: 100vh;
}

section.center-inner .inner {
	max-width: 1024px;
	padding: 0 0.25rem;
	margin: 0 auto;
}

/*
    Logo - Open
 */
.logo {
	display: inline-block;
	background: white;
	color: black;
	text-align: center;
	font-family: 'Marck Script', cursive;
}

.logo.plain {
	background: none;
	color: white;
}

.logo.small {
	font-size: 0.875rem;
	line-height: 30px;
	width: 30px;
	border-radius: 30px;
}

.logo.medium {
	font-size: 3rem;
	line-height: 110px;
	width: 110px;
	border-radius: 110px;
}

.logo.large {
	font-size: 3rem;
	line-height: 150px;
	width: 150px;
	border-radius: 150px;
}

.logo::before {
	content: 'A+E';
	display: inline-block;
}

/*
    Logo - Close
 */


/*
   Navigation - Open
*/
nav {
	position: fixed;
	top: 0;
	left: 0; right: 0;
	z-index: 1;
}

nav .inner-nav {
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
	background: rgba(0, 0, 0, 0.3);
	color: white;
}

nav .logo {
	display: inline-block;
	font-size: 1rem;
}

nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: inline-block;
}

nav ul li {
	display: inline-block;
}

nav ul li a {
	text-decoration: none;
	color: white;
}

/*
    Navigation - Close
 */


/*
	Polaroid - Open
 */

.polaroid-container {
	width: 220px;/*200px wide img + 10px padding*/
	height: 270px;/*200px tall img + 10px top padding + 60px bottom padding*/
	position: relative;
	perspective: 800px;
}

.polaroid-container .polaroid {
	position: absolute;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 750ms ease-in-out;
}

.polaroid-container .polaroid .front,
.polaroid-container .polaroid .back {
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	backface-visibility: hidden;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}

.polaroid-container .polaroid .front {
	padding: 10px 10px 0;
}

.polaroid-container .polaroid .front .img {
	width: 200px;
	height: 200px;
	background-size: cover;
}

.polaroid-container .polaroid .front .caption {
	display: block;
	height: 60px;
	padding: 0.5rem 0;
}

.polaroid-container .polaroid .back {
	transform: rotateY(180deg);
	padding: 20px 10px;
}

.polaroid-container .polaroid .back .caption {
	display: block;
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.polaroid-container:hover .polaroid,
.polaroid-container:focus .polaroid {
	transform: rotateY(180deg);
}


@media only screen and (min-width: 340px) {
	.polaroid-container.medium,
	.polaroid-container.large {
		width: 330px;/*300px wide img + 15px padding*/
		height: 405px;/*300px tall img + 15px top padding + 90px bottom padding*/
	}

	.polaroid-container.medium .polaroid .front,
	.polaroid-container.large .polaroid .front {
		padding: 15px 15px 0;
	}

	.polaroid-container.medium .polaroid .front .img,
	.polaroid-container.large .polaroid .front .img {
		width: 300px;
		height: 300px;
	}

	.polaroid-container.medium .polaroid .front .caption,
	.polaroid-container.large .polaroid .front .caption {
		height: 90px;
		font-size: 1.5rem;
		padding: 0.75rem 0;
	}

	.polaroid-container.medium .polaroid .back,
	.polaroid-container.large .polaroid .back {
		padding: 30px 20px;
	}

	.polaroid-container.medium .polaroid .back .caption,
	.polaroid-container.large .polaroid .back .caption {
		display: block;
		font-size: 1.5rem;
		margin-bottom: 0.875rem;
	}
}


@media only screen and (min-width: 450px) {
	.polaroid-container.large {
		width: 440px;/*400px wide img + 20px padding*/
		height: 540px;/*400px tall img + 20px top padding + 120px bottom padding*/
	}

	.polaroid-container.large .polaroid .front {
		padding: 20px 20px 0;
	}

	.polaroid-container.large .polaroid .front .img {
		width: 400px;
		height: 400px;
	}

	.polaroid-container.large .polaroid .front .caption {
		height: 120px;
		font-size: 2rem;
		padding: 0.875rem 0;
	}

	.polaroid-container.large .polaroid .back {
		padding: 40px 30px;
	}

	.polaroid-container.large .polaroid .back .caption {
		display: block;
		font-size: 2rem;
		margin-bottom: 1rem;
	}

}



/*
	Polaroid - Close
 */

/*
   Home section - Open
 */
.page-section.home {
	background-color: black;
	position: relative;
}

.page-section.home .hero {
	background-image: url('../images/hero.jpg');
	background-size: cover;
	background-position: center center;
	max-width: 1300px;
	height: 100%;
	margin: 0 auto;
}

/*
    Home Section - Close
 */



/*
	Info seperator section - Open
 */
.seperator-section.info {
	background-color: #494949;
	margin-top: -100px;
	padding-bottom: 1rem;
}

.seperator-section.info .card {
	background-color: #353535;
	max-width: 640px;
	padding: 0.3rem 0.3rem 1.5rem;
	position: relative;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}

.seperator-section.info .card .info {
	display: flex;
	padding: 0 0 1rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px dashed white;
}

.seperator-section.info .card .info .left {
	flex: 0 0 25%;
	text-align: center;
	display: none;
}

.seperator-section.info .card .info .right {
	flex: 1 1 auto;
	color: white;
	text-align: center;
}

.seperator-section.info .card .info .logo {
	color: #494949;
}

.seperator-section.info .card .info .name {
	font-size: 2rem;/*3*/
}

.seperator-section.info .card .info .conjunction {
	font-size: 1rem;/*2*/
	margin: 0 0.3rem;
}

.seperator-section.info .card .info .engaged {
	display: block;
	font-size: 1.125rem;
	line-height: 1.5;
}

.seperator-section.info .card .info .when-where {
	font-size: 1.5rem;/*1.75*/
	line-height: 1.2;
	margin-top: 1rem;
}

.seperator-section.info .card .info .when-where span {
	display: inline-block;
}

.seperator-section.info .card .info .location::after {
	content: '\b7';
	margin: 0 0.3rem;
	display: inline-block;
}

.seperator-section.info .card .img-wrapper .polaroid-container {
	margin: 0 auto;
}


@media only screen and (min-width: 440px) {
	.seperator-section.info .card .info .name {
		font-size: 3rem;
	}

	.seperator-section.info .card .info .conjunction {
		font-size: 2rem;
		margin: 0 1rem;
	}
	.seperator-section.info .card .info .location::after {
		margin: 0 1rem;
	}

	.seperator-section.info .card .info .when-where {
		font-size: 1.75rem;
	}

	.seperator-section.info .card {
		padding: 0.5rem 0.5rem 2rem;
	}

	.seperator-section.info .card .info {
		margin-bottom: 2rem;
	}
}

@media only screen and (min-width: 640px) {
	.seperator-section.info {
		margin-top: -150px;
	}

	.seperator-section.info .card {
		padding: 1rem 1rem 3rem;
	}

	.seperator-section.info .card .info {
		margin-bottom: 3rem;
	}

	.seperator-section.info .card .info .left {
		display: block;
	}
}

/*
	Info seperator section - Close
 */

/*
 	Story section - Open
 */

.page-section.story {
	background: #494949;
}

/*
	Story section - Close
 */

